import React from 'react'
import { useCalculatorMachine } from '../../utils/context'
import { ErrorScreen } from './ErrorScreen'
import { QuoteComponentMapper } from '../quoteComponents/QuoteComponentMapper'
import { CalculatorStepCommon } from '../steps/CalculatorStepCommon'
type CalcType = 'Pensions' | 'Investment'

export const ViewSelector: React.FC<any> = (props) => {
    const calcType: CalcType = props.props.calc_type
    const QuoteComponent = QuoteComponentMapper[calcType]
    const { state } = useCalculatorMachine()
    switch (true) {
        case state.matches('Quote Screen') ||
            state.matches('Refetching Quote Details'):
            return <QuoteComponent />
        case state.matches('Error'):
            return <ErrorScreen />
        default:
            return <CalculatorStepCommon {...props} />
    }
}
