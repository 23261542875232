import { Box } from '@chakra-ui/react'
import React from 'react'
import { useCalculatorMachine } from '../../../utils/context'
import { BenefitsCard } from '../common/BenefitsCard'
import { TermLifeCalculatorOutput } from './TermLifeCalculatorOutput'
import { ValidityCard, ValidityCardProps } from '../common/ValidityCard'
import { HaveAQuestionCard } from '../common/HaveAQuestion'
import { isEmpty } from 'lodash'

export interface TermLifeQuoteProps {
    props?: any
}
export const TermLifeQuote: React.FC<TermLifeQuoteProps> = () => {
    const { state, send } = useCalculatorMachine()
    if (
        state.context.quoteData?.error ||
        isEmpty(state.context.quoteData?.quoteData)
    ) {
        send({ type: 'ERROR OCCURED' })
    }
    const phoneNumber = '(01) 704 1104'
    const benefits = [
        `98.1% of death claims paid (2022)`,
        `Supporting 7,000+ families with claims (2022)`,
        `80 years looking after the needs of people in Ireland`,
    ]
    const benefitsTitle = `Why choose Irish Life Assurance?`
    const validity: ValidityCardProps = {
        title: 'Validity',
        description:
            'This quote is valid either for 7 days or to your next birthday, whichever is sooner. If you would like to discuss options for Joint Life Cover or Dual Life Cover,   you can book a free consultation with a financial advisor.',
    }
    return (
        <Box>
            <TermLifeCalculatorOutput />
            <ValidityCard {...validity} />
            <HaveAQuestionCard phoneNumber={phoneNumber}/>
            <BenefitsCard benefits={benefits} title={benefitsTitle} />
        </Box>
    )
}
