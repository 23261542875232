import React from 'react'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { GetStartedProps } from '../../common/GetStarted'
import { AdjustmentFormProps } from '../../common/AdjustmentForm'
import {
    MortgageProtectionFormType,
    CalculatorMachineEvent,
} from 'common/components/organisms/Calculator/utils/interfaces'
import {
    CalculatorQuoteDisplay,
    CalculatorQuoteDisplayProps,
} from '../../common/CalculatorQuoteDisplay'

export interface MortgageProtectionOutputProps {}

export const MortgageProtectionOutput: React.FC<MortgageProtectionOutputProps> =
    () => {
        const { state, send } = useCalculatorMachine()
        const formValues = state.context
            .formValues as MortgageProtectionFormType

        const getStarted: GetStartedProps = {
            title: 'Get Started',
            subtitle:
                'You don’t have to wait until your mortgage is drawn down to begin.',
            phoneNumber: '(01) 704 1104',
            bodyText:
                'This indicative quote from Irish Life Financial Services is for a Mortgage Protection policy from Irish Life Assurance plc and is subject to further assessment of health status. This assessment may result in an increase in the cost of cover. Irish Life Assurance plc, trading as Irish Life, is regulated by the Central Bank of Ireland.',
        }
        const onSubmit = (data: any) => {
            if (!data.coverNeeded && !data.term) {
                return
            }
            let user = {}
            if (state.context.formValues) {
                user = {
                    ...state?.context?.formValues,
                    coverNeeded: data.coverNeeded || formValues.coverNeeded,
                    term: data.term || formValues.term,
                }
            }
            send({
                type: 'Get Adjusted Quote',
                payload: { user },
            } as CalculatorMachineEvent)
        }
        const adjustmentFormProps: AdjustmentFormProps = {
            onSubmit,
            coverNeeded: formValues.coverNeeded,
            term: formValues.term,
            peopleCovered:
                formValues.coverFor === '1' ? 'One person' : 'Two people',
        }

        const quoteDisplayProps: CalculatorQuoteDisplayProps = {
            getStarted,
            adjustmentFormProps,
        }
        return <CalculatorQuoteDisplay {...quoteDisplayProps} />
    }
