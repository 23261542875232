import numeral from 'numeral'

export const formatValue = (value: number, type: string) => {
    switch (type) {
        case 'percentage':
            return `${numeral(value).format('0')}%`
        case 'currency':
            return `€ ${numeral(value).format('0,0')}`
        default:
            return `${numeral(value).format('0')}`
    }
}

export const getOccupationsList = (occupations: any) => {
    return occupations.filter((item: any) => {
        return item.BillPayOccupationClass !== 'D'
    })
}


