import React from 'react';
import { IBulletWithIcon } from './BulletWithIcon.interface';
import { Box, Image } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { RichText } from '../RichText';

const BulletWithIcon = ({
  bullet,
  titleProps,
  textProps,
  iconProps,
  mainDivProps,
}: IBulletWithIcon) => {
  return (
    <Box display={'flex'} gap={'8px'} alignItems={'center'} {...mainDivProps}>
      <Box display={'flex'}>
        {bullet.icon && (
          <Image
            src={bullet.icon}
            width={'24px'}
            height={'24px'}
            alt=""
            {...iconProps}
          />
        )}
      </Box>
      <Box display={'flex'}>
        {bullet.title && <Text {...titleProps}>{bullet.title}</Text>}
        {bullet.text && <RichText {...textProps} label={bullet.text} html />}
      </Box>
    </Box>
  );
};

export { BulletWithIcon };
