import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import {
  getCallToActionData,
  getSecondaryCallToActionData,
} from './formatButtons';
import { BulletWithIcon } from 'common/components/molecules/BulletWithIcon';
import { RichText } from 'common/components/molecules/RichText';
import styled from 'styled-components';
import { useDesktop } from 'common/hooks/useDesktop';
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent';
import { navigate } from '@reach/router';
import { IAdvisorBlockV4 } from './AdvisorBlockV4.interface';

const StyledRichText = styled(RichText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #4e4670;
`;

const StyledRichTextMobile = styled(RichText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #4e4670;
`;

const AdvisorBlockV4: React.FC<any> = (props: IAdvisorBlockV4) => {
  const isDesktop = useDesktop();
  const callToAction = getCallToActionData(props);
  const customcampaignId = props.custom_campaignid_to_booking_appointment_form
    ? `?campaignIdAdvisorBlock=${props.custom_campaignid_to_booking_appointment_form}`
    : '';
  const secondaryCallToAction = getSecondaryCallToActionData(props);

  return (
    <Box
      display={'flex'}
      flexDirection={['column', 'column', 'column', 'row']}
      className="advisor-block-v4"
      paddingTop={'20px'}
      paddingBottom={'20px'}
      gap={['16px', '16px', '16px', '96px']}
    >
      <Box
        className="frame-2842"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        gap={'24px'}
      >
        <Box
          className="frame-2825"
          display={'flex'}
          flexDirection={'column'}
          gap={'16px'}
        >
          {props.left_subtitle && props.left_subtitle.length > 0 && (
            <Text
              className="caption"
              variant={'caption-xl'}
              color={'#4D599E'}
              as={props.left_subtitle[0].html_tag}
            >
              {props.left_subtitle[0].text}
            </Text>
          )}

          <Box
            className="2811"
            display={'flex'}
            flexDirection={'column'}
            gap={'16px'}
          >
            {props.left_title && props.left_title.length > 0 && (
              <Text
                className="headline"
                variant={'title-lg'}
                color={'#4E4670'}
                as={props.left_title[0].html_tag}
              >
                {props.left_title[0].text}
              </Text>
            )}
            {props.left_body_text && (
              <>
                {isDesktop && (
                  <StyledRichText
                    label={props.left_body_text}
                    className="body-text"
                    html
                  />
                )}

                {!isDesktop && (
                  <StyledRichTextMobile
                    label={props.left_body_text}
                    className="body-text"
                    html
                  />
                )}
              </>
            )}
          </Box>
        </Box>

        <Box
          className="frame-3453"
          display={'flex'}
          flexDirection={['column', 'column', 'column', 'row']}
          gap={'16px'}
        >
          {callToAction?.url && (
            <Button
              _focusVisible={{ outline: '2px solid black' }}
              flex={['none', 'none', 'none', '1']}
              height={['44px', '44px', '44px', '60px']}
              variant="vibrant"
              size="lg"
              maxWidth={isDesktop ? '50%' : '100%'}
              leftIcon={<Image src={callToAction.leftIcon} alt="" />}
              rightIcon={<Image src={callToAction.rightIcon} alt="" />}
              onClick={(e) => {
                e.preventDefault();
                fireAnalyticsEvent({
                  name: 'primaryCtaAdvisorBlockV4',
                  properties: {
                    clickText: callToAction.label,
                    clickURL: callToAction.url,
                    clickID: callToAction.analyticsId,
                  },
                });
                navigate(`${callToAction.url}${customcampaignId}`);
              }}
            >
              {callToAction.label}
            </Button>
          )}
          {secondaryCallToAction?.url && (
            <Button
              _focusVisible={{ outline: '2px solid black' }}
              flex={['none', 'none', 'none', '1']}
              height={['44px', '44px', '44px', '60px']}
              variant="outline"
              size="lg"
              maxWidth={isDesktop ? '50%' : '100%'}
              leftIcon={<Image src={secondaryCallToAction.leftIcon} alt="" />}
              rightIcon={<Image src={secondaryCallToAction.rightIcon} alt="" />}
              onClick={(e) => {
                e.preventDefault();
                fireAnalyticsEvent({
                  name: 'secondaryCtaAdvisorBlockV4',
                  properties: {
                    clickText: secondaryCallToAction.label,
                    clickURL: secondaryCallToAction.url,
                    clickID: secondaryCallToAction.analyticsId,
                  },
                });
                navigate(secondaryCallToAction.url);
              }}
            >
              {secondaryCallToAction.label}
            </Button>
          )}
        </Box>
        {props.left_regulatory_information && isDesktop && (
          <Text
            className="regulatory-information"
            variant="footer"
            color="#4E4670"
          >
            {props.left_regulatory_information}
          </Text>
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        className="frame-2810"
        borderRadius={'12px'}
        backgroundColor={'var(--Colour-Vibrant-50, #EEF0FC)'}
        paddingTop={'32px'}
        paddingBottom={'32px'}
        paddingLeft={'16px'}
        paddingRight={'16px'}
        minW={['0px', '0px', '0px', '480px']}
        gap={'24px'}
        marginTop={['32px', '32px', '32px', '0px']}
      >
        {props.right_subtitle && props.right_subtitle.length > 0 && (
          <Text
            variant={['title-xs', 'title-xs', 'title-xs', 'title-sm']}
            color={['#0C1E68', '#0C1E68', '#0C1E68', '#0C1E68']}
            as={props.right_subtitle[0].html_tag}
          >
            {props.right_subtitle[0].text}
          </Text>
        )}
        {props.bullet_points &&
          props.bullet_points.length > 0 &&
          props.bullet_points.map((bullet) => (
            <BulletWithIcon
              key={`bullet-${bullet.codename}`}
              bullet={bullet}
              titleProps={{
                variant: 'subtitle-sm',
                fontWeight: 'semibold',
                color: '#0C1E68',
              }}
            />
          ))}
        {props.advisor_image &&
          props.advisor_message &&
          props.advisor_name &&
          props.advisor_role && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              className="advisor-lockup"
              gap={'16px'}
            >
              <Text variant={'subtitle-sm'} color={'#4E4670'}>
                {props.advisor_message}
              </Text>
              <Box display={'flex'} className="frame-2788" gap={'8px'}>
                <Image
                  src={props.advisor_image}
                  alt={`Photo of ${props.advisor_name}, ${props.advisor_role}`}
                  width={'64px'}
                  height={'64px'}
                  borderRadius={'full'}
                />
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                >
                  <Text variant={'label-md'} color={'#4E4670'}>
                    {props.advisor_name}
                  </Text>
                  <Text variant={'label-md'} color={'#615A80'}>
                    {props.advisor_role}
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
      </Box>
      {props.left_regulatory_information && !isDesktop && (
        <Text
          className="regulatory-information"
          variant="footer"
          color="#4E4670"
          marginTop={['10px', '10px', '10px', '0px']}
        >
          {props.left_regulatory_information}
        </Text>
      )}
    </Box>
  );
};

export { AdvisorBlockV4 };
