import React from 'react'
import { Box, Flex, VStack, Square } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { useIncomeProtectionCalculatorCalculator } from '../utils/useIncomeProtectionCalculator'

export default function Charts() {
    const {
        state: { income, coveredIncome, stateCover },
    } = useIncomeProtectionCalculatorCalculator()
    const amountNotCovered = income - coveredIncome - stateCover
    const heightOfNotCovered = (amountNotCovered / income) * 131
    const heightOfCovered = (coveredIncome / income) * 131
    const heightOfStateCovered = (stateCover / income) * 131
    return (
        <Flex>
            <VStack align={'start'} h={'131px'} gap={'3px'}>
                <Box
                    mr={'24px'}
                    borderRadius={'2px 2px 0px 0px'}
                    backgroundColor={'var(--prim-colour-purple-800, #5463D6)'}
                    h={`${heightOfCovered}px`}
                    w={'64px'}
                />
                <Box
                    mr={'24px'}
                    backgroundColor={'var(--il-color-input-border-disabled, #8983A0)'}
                    h={`${heightOfNotCovered}px`}
                    w={'64px'}
                    mt={'0px !important'}
                />
                <Box
                    mr={'24px'}
                    borderRadius={'0px 0px 2px 2px'}
                    backgroundColor={'var(--prim-colour-purple-200, #CFD4FA)'}
                    h={`${heightOfStateCovered}px`}
                    w={'64px'}
                    mt={'0px !important'}
                />
            </VStack>
            <VStack align={'start'} gap={'18px'}>
                <Flex mt={'0px !important'}>
                    <Square
                        size={'22px'}
                        borderRadius={'2px'}
                        backgroundColor={'var(--prim-colour-purple-800, #5463D6)'}
                    ></Square>

                    <Box ml={'12px'}>
                        <Text variant={'caption-lg'} color={'var(--Monochrome-800, var(--colour-grey-800, #615A80))'} mb={'4px'}>
                            Covered by your plan
                        </Text>
                        <Text
                            variant={'caption-lg'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${coveredIncome.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
                <Flex my={'18px'}>
                    <Square
                        borderRadius={'2px'}
                        size={'22px'}
                        backgroundColor={'var(--il-color-input-border-disabled, #8983A0)'}
                    ></Square>
                    <Box ml={'12px'}>
                        <Text variant={'caption-lg'} color={'var(--Monochrome-800, var(--colour-grey-800, #615A80))'} mb={'4px'}>Not covered</Text>
                        <Text
                            variant={'caption-lg'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${amountNotCovered.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
                <Flex mt={'0px !important'}>
                    <Square
                        borderRadius={'2px'}
                        size={'22px'}
                        backgroundColor={'var(--prim-colour-purple-200, #CFD4FA)'}
                    ></Square>

                    <Box ml={'12px'}>
                        <Text variant={'caption-lg'} color={'var(--Monochrome-800, var(--colour-grey-800, #615A80))'} mb={'4px'}>Covered by benefits</Text>
                        <Text
                            variant={'caption-lg'}
                            color={'var(--Brand-800, #1D2F7C)'}
                        >
                            {`€${stateCover.toLocaleString()}`}
                        </Text>
                    </Box>
                </Flex>
            </VStack>
        </Flex>
    )
}
