import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { RichText } from 'common/components/molecules/RichText'
import AddCircleIcon from './AddCircle'

export interface MoreInfoProps {
    modalContent: string
    buttonLabel: string
    img?: string
    imgAltText?: string
}

export const MoreInfo: React.FC<MoreInfoProps> = ({
    modalContent,
    buttonLabel,
    img,
    imgAltText
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const finalRef = React.useRef(null)
    const handleHelpButton = () => {
        onOpen()
    }
    // const modalContent = getModelContent()
    return (
        <Flex align='start' justify='flex-start' wrap='nowrap' gap={'3rem'}>
            <>
                <Button
                    size='sm'
                    variant='ghost'
                    px={'0'}
                    my={'16px'}
                    leftIcon={!img ? <AddCircleIcon /> : undefined}
                    onClick={handleHelpButton}
                >
                    {img && (<img
                        style={{ paddingRight: '8px' }}
                        alt={imgAltText}
                        src={img}
                    />)}
                    {buttonLabel}
                </Button>
                <Modal
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />
                    <ModalContent p={'16px'} style={{ margin: '16px' }}>
                        <ModalCloseButton />
                        <ModalBody>
                            <RichText label={modalContent} html></RichText>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme='blue'
                                size='sm'
                                width={'100%'}
                                onClick={onClose}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </>
        </Flex>
    )
}

// export const getModelContent = () => {
//     return `<h6>How your income is covered</h6>
// <p>Income Protection can replace your monthly income by up to 75% of your earnings after an accident or illness.</p>
// <p><br></p>
// <h6>Covered by your plan</h6>
// <p>This is the amount that is covered by your income protection plan.</p>
// <p><br></p>
// <h6>Covered by benefits</h6>
// <p>This is the amount that may be covered by the state and other benefit plans.</p>
// <p><br></p>
// <h6>Not covered</h6>
// <p>This is the amount that is not covered as you can only cover up to 75% of your annual earnings.</p>
// <p><br></p>`
// }