import { APIrequest } from './APIrequests'
import { API_URL } from 'core'
import qs from 'qs'
import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'
// const API_URL = 'https://apps.irishlife.ie'
const API: string = `${API_URL}/myonlineservices/servlet/LifeCoverQuote`
const stateCover = 12732
export const incomeProtectionTool = {
    onSubmit: (data: any) => onSubmit(data),
    stepsTransformation: (steps: any, data?: any) => {
        return steps
    },
}

export const onSubmit = (data: any): Promise<any> => {
    return APIrequest(API, getApiConstants('incomeProtection', 102, data)).then(
        (response: any) => {
            if (response !== undefined) {
                return response
            }
        }
    )
}
const calculateIncomeAmount = (data: any) => {
    return (
        (Number(data.income) * Number(data.amountToProtect)) / 100 - stateCover
    )
}
export const getOccupationClass = (occupation: string) => {
    const occupationClass = occupations.find(
        (item) => item.value === occupation
    )
    return occupationClass ? occupationClass : undefined
}
// export const getDateOfBith = (dateOfBirth: string) => {
//     const date = new Date(dateOfBirth)
//     return {
//         day: date.getDate(),
//         month: date.getMonth() + 1,
//         year: date.getFullYear(),
//     }
// }
export const getApiConstants: any = (
    quickQuoteId: string,
    productId: number,
    data: any
) => {
    const currentDate = new Date();
    const incomeAmount = calculateIncomeAmount(data)
    const occupationClass = getOccupationClass(data.occupation)
    return qs.stringify({
        quickQuoteId: quickQuoteId,
        productId: productId,
        coverTypeCd: 'L',
        jointLife: 'False',
        indexation: 'False',
        frequencyCd: 'M',
        dateOfBirth1Day: currentDate.getDate(),
        dateOfBirth1Month: currentDate.getMonth() + 1, // Months are 0-indexed,
        dateOfBirth1Year: currentDate.getFullYear() - +data.dateOfBirth,
        sexCd1: 'M',
        smokerCd1: data.isSmoker === 'Y' ? 'Y' : 'N',
        incomeIncreaseRate: 5,
        occupationClass: occupationClass?.BillPayOccupationClass,
        incomeAmt: incomeAmount,
        deferredWeeks: 26,
        endAge: data.retirementAge,
    })
}
