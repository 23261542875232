import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { PhoneIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
import { quoteScreenAnalytics } from '../../../config/analytics'
import { useCalculatorMachine } from '../../../utils/context'

export interface GetStartedProps {
    title: string
    subtitle?: string
    phoneNumber: string
    bodyText?: string
    secondaryCTALabel?: string
    secondaryCTALink?: string
    isPhoneNumber?: boolean
    link?: string
    linkLabel?: string
}

export const GetStarted: React.FC<GetStartedProps> = ({ ...props }) => {
    const { title,
        subtitle,
        phoneNumber,
        bodyText,
        secondaryCTALabel,
        secondaryCTALink,
        isPhoneNumber = true,
        link,
        linkLabel } = props
    const phone = `tel: +353${phoneNumber}`
    const { state } = useCalculatorMachine()
    const calcType = state.context.calc_type
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Text variant={'title-xs'}>{title}</Text>
            <Box mt={'16px'}>
                {subtitle && (
                    <Text mb={'16px'} variant={'body-md'}>
                        {subtitle}
                    </Text>
                )}
                <Box width={'100%'} mb={'24px'}>
                    {isPhoneNumber && (<a href={phone} onClick={() => quoteScreenAnalytics(calcType, getAnalyticsParams(phoneNumber, `#${phoneNumber}`, 'click_To_Call'), isPhoneNumber)}>
                        <Button
                            width={'100%'}
                            leftIcon={
                                <PhoneIcon fontSize={'28px'} color={'#FFFFF'} />
                            }
                            variant='primary'
                            size='md'
                        >
                            {`Call us ${phoneNumber}`}
                        </Button>

                    </a>)}
                    {!isPhoneNumber && (
                        <a href={link} onClick={() => quoteScreenAnalytics(calcType, getAnalyticsParams(linkLabel || '', `#${link}`, 'primary_cta_click'))}>
                            <Button
                                width={'100%'}
                                variant='primary'
                                size='md'
                            >
                                {linkLabel}
                            </Button>

                        </a>)}
                </Box>

                {secondaryCTALabel && (
                    <a href={secondaryCTALink} onClick={() => quoteScreenAnalytics(calcType, getAnalyticsParams(secondaryCTALabel, `#${secondaryCTALink}`, 'secondary_CTA_Click'))}>
                        <Button
                            width={'100%'}
                            variant='outline'
                            size='md'
                        >
                            {secondaryCTALabel}
                        </Button>
                    </a>
                )}

                {bodyText && (
                    <Text mt={'24px'} variant={'body-md'}>
                        {bodyText}
                    </Text>
                )}
            </Box>
        </Box>
    )
}

const getAnalyticsParams = (text: string, url: string, id: string) => {
    return {
        text: text,
        url: url,
        analyticsId: id
    }
}
