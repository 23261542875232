import { Box, FormLabel } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import React from 'react'
import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'

export interface CalculatorStepDropdownProps {
    fieldName: string
    name?: string
    label?: string
    options?: any[]
    control?: any
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        input: {
            backgroundColor: 'var(--chakra-colors-brand-50)',
            '&:hover': {
                backgroundColor: '#F1F3FD',
            },
            '&$focused': {
                backgroundColor: '#F1F3FD',
            },
            disableUnderline: true,
            paddingLeft: '18px',
            borderStyle: 'solid',
            borderWidth: '1px', // Add this line
            borderRadius: 'var(--chakra-radii-md)',
            borderColor: 'var(--chakra-colors-brand-400)',
            height: 'var(--chakra-sizes-12)',
            paddingTop: '7px',
        },
        parent: {
            width: 'var(--chakra-sizes-full)',
            // maxWidth: '100%',
        },
        margin: {
            margin: theme.spacing(1),
        },
    })
)
export const CalculatorStepDropdown: React.FC<CalculatorStepDropdownProps> = ({
    name,
    label,
    options,
    fieldName,
    control,
}) => {
    const classes = useStyles()
    return (
        <Controller
            name={fieldName ?? name}
            control={control}
            defaultValue=''
            render={({ onChange, value }) => (
                <Box>
                    <FormLabel>
                        <Text
                            variant={'body-md'}
                            color={'monochrome.800'}
                            fontWeight={'semibold'}
                        >
                            {label}
                        </Text>
                    </FormLabel>
                    <Autocomplete
                        options={occupations}
                        className={classes.parent}
                        onChange={(event, newValue) => {
                            onChange(newValue?.value || '')
                        }}
                        renderInput={(params) => (
                            <TextField
                                autoComplete='true'
                                {...params}
                                className={classes.input}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                id='bootstrap-input'
                                placeholder='Start typing to select an option'
                            />
                        )}
                        getOptionLabel={(option) => option.label}
                    />
                </Box>
            )}
        />
    )
}
