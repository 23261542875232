import React from 'react'
import { ValidityCard, ValidityCardProps } from '../common/ValidityCard'
import { HaveAQuestionCard } from '../common/HaveAQuestion'
import { isEmpty } from 'lodash'
import { useCalculatorMachine } from '../../../utils/context'
import { BenefitsCard } from '../common/BenefitsCard'
import { Box } from '@chakra-ui/react'
import { MortgageProtectionOutput } from './MortgageProtectionOutput'
export interface MortgageProtectionQuoteProps {
    props?: any
}
export const MortgageProtectionQuote: React.FC<MortgageProtectionQuoteProps> =
    () => {
        const { state, send } = useCalculatorMachine()
        if (
            state.context.quoteData?.error ||
            isEmpty(state.context.quoteData?.quoteData)
        ) {
            send({ type: 'ERROR OCCURED' })
        }
        const phoneNumber = '(01) 704 1104'
        const benefits = [
            `98.1% of death claims paid (2022)`,
            `Supporting 7,000+ families with claims (2022)`,
            `80 years looking after the needs of people in Ireland`,
        ]
        const benefitsTitle = `Why choose Irish Life Assurance?`
        const validity: ValidityCardProps = {
            title: 'Validity',
            description:
                'This quote is valid for 7 days or until your next birthday, depending which comes first.',
        }
        return (
            <Box>
                <MortgageProtectionOutput />
                <ValidityCard {...validity} />
                <HaveAQuestionCard phoneNumber={phoneNumber}/>
                <BenefitsCard benefits={benefits} title={benefitsTitle} />
            </Box>
        )
    }
