import { Box, Flex } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { InfoIcon, TickIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import { ChevronRightIcon } from '@irishlife/ilgroupdesignsystem.icons'

export const RestrictionScreen: React.FC = () => {
    return (
        <Card>
            <Flex align='start' justify='flex-start' wrap='nowrap'>
                <InfoIcon fontSize={'32px'} color={'#3276C7'} mr={'8px'} />
                <Text variant={'title-xs'}>
                    Sorry, we were unable to complete your quote
                </Text>
            </Flex>

            <Box py={'24px'}>
                <Text mb={'24px'}>
                    Based on your occupation, you might not qualify for income protection. You might still qualify for other forms of protection, therefore it would be best that you speak with one of our financial advisors.
                </Text>
                <Box display='flex' mb={'24px'} columnGap={'8px'}>
                    <TickIcon color='accent.500' w={'24px'} h={'24px'} />
                    <Box>
                        <Text variant={'subtitle-sm'} fontWeight={'semibold'} mb={'4px'}>You can still continue</Text>
                        <Text variant={'body-md'}>An advisor can assess your situation and see if you qualify for income protection.</Text>

                    </Box>
                </Box>
                <Box display='flex' columnGap={'8px'}>
                    <TickIcon color='accent.500' w={'24px'} h={'24px'} />
                    <Box>
                        <Text variant={'subtitle-sm'} fontWeight={'semibold'} mb={'4px'}>You may qualify for other protection</Text>
                        <Text variant={'body-md'}>An advisor can see if you qualify for alternative protection such as bill cover.</Text>

                    </Box>
                </Box>
            </Box>

            <a href={'/book-an-appointment-page?campaignIdAdvisorBlock=income_protection_calc_quotescreen'} onClick={() => fireAnalyticsEvent({
                name: 'IncomeProtection',
                properties: {
                    event: 'IncomeProtection',
                    clickText: 'Book an appointment',
                    clickURL: '/book-an-appointment-page?campaignIdAdvisorBlock=income_protection_calc_quotescreen',
                    clickID: 'primary_cta_click'
                }
            })}>
                <Button
                    rightIcon={<ChevronRightIcon />}
                    width={'100%'}
                    variant='primary'
                    size='md'
                >
                    Book an appointment
                </Button>

            </a>

        </Card>
    )
}
