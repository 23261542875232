import { TeamGridFilter } from 'common/components/organisms/TeamGridFilter/index'
import React, { FC } from 'react'

const TeamGridFilterWrapper: FC<{ props: any }> = (props) => {
    const processedProps = getProps(props)

    return <TeamGridFilter {...processedProps} />
}
export { TeamGridFilterWrapper as TeamGridFilter }

const getProps = (props: any) => {
    const { title, team_members } = props
    
    return {
        title,
        cards: team_members.map((item: any) => ({
            name: item.full_name,
            jobTitle: item.job_title,
            company: item.company,
            department: item.department,
            image: item.image,
        })),
        showFilters: props.show_filters,
        pagination: props.pagination,
        members_card_style: props.members_card_style,
        callToAction: {
            buttonLabel: props.button_c9ad60e__text,
            buttonIcon: props.button_c9ad60e__icon,
            buttonUrl: props.button_c9ad60e__url,
        },
    }
}
