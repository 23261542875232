import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { debounce } from 'lodash'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import {
    CalculatorMachineEvent,
    IncomeProtectionFormType,
} from 'common/components/organisms/Calculator/utils/interfaces'
import { GetStartedProps } from '../../../../common/GetStarted'
import { MoreInfoProps } from '../../../../common/MoreInfo'

export const incomeProtectionCalculatorState = atom({
    key: 'incomeProtectionCalculatorState',
    default: {
        income: 0,
        amountToProtect: 0,
        premium: 0,
        coveredIncome: 0,
        stateCover: 12732,
    },
})

export function useIncomeProtectionCalculatorCalculator() {
    const [calcState, setCalcState] = useRecoilState(
        incomeProtectionCalculatorState
    )
    const { state, send } = useCalculatorMachine()

    useEffect(() => {
        if (state.context?.formValues) {
            const income = Number(
                (state.context.formValues as IncomeProtectionFormType).income ||
                0
            )

            setCalcState({
                income,
                amountToProtect: Number(
                    (state.context.formValues as IncomeProtectionFormType)
                        .amountToProtect || 0
                ),
                premium: Number(
                    (
                        state.context as {
                            quoteData?: { quoteData?: { prem?: number } }
                        }
                    ).quoteData?.quoteData?.prem ?? 0
                ),
                coveredIncome: Number(
                    (income *
                        Number(
                            (
                                state.context
                                    .formValues as IncomeProtectionFormType
                            ).amountToProtect || 0
                        )) /
                    100 -
                    12732
                ),
                stateCover: calcState.stateCover,
            })
        }
    }, [state.context, setCalcState, calcState.stateCover])

    const debouncedUpdate = useCallback(
        debounce((income, amountToProtect) => {
            let user = {}
            user = {
                ...state?.context?.formValues,
                income: income,
                amountToProtect: amountToProtect,
            }
            send({
                type: 'Get Adjusted Quote',
                payload: { user },
            } as CalculatorMachineEvent)
        }, 1500),
        []
    )

    const changeUserContribution = useCallback(
        (income, amountToProtect) => {
            debouncedUpdate(income, amountToProtect)
        },
        [debouncedUpdate]
    )

    const setYearlyIncome = useCallback(
        (income) => {
            setCalcState((oldState) => ({
                ...oldState,
                income: income,
            }))
            changeUserContribution(income, calcState.amountToProtect)
        },
        [setCalcState, calcState.amountToProtect, changeUserContribution]
    )

    const setAmountToProtect = useCallback(
        (amountToProtect) => {
            setCalcState((oldState) => ({
                ...oldState,
                amountToProtect: amountToProtect,
            }))
            changeUserContribution(calcState.income, amountToProtect)
        },
        [setCalcState, calcState.income, changeUserContribution]
    )

    return {
        state: calcState,
        actions: {
            setYearlyIncome,
            setAmountToProtect,
        },
    }
}

export const getStarted: GetStartedProps = {
    title: 'Next Steps',
    phoneNumber: '(01) 704 1104',
    bodyText: 'This indicative quote from Irish Life Financial Services is for a Income Protection policy provided by Irish Life Assurance plc and is subject to further assessment of health status. This assessment may result in an increase in the cost of cover. Irish Life Assurance plc is regulated by the Central Bank of Ireland.',
    secondaryCTALabel: 'Learn about Income Protection',
    secondaryCTALink: '/life-insurance/income-protection',
    link: '/book-an-appointment-page?campaignIdAdvisorBlock=income_protection_calc_quotescreen',
    linkLabel: 'Book an appointment',
    isPhoneNumber: false
}

export const getModelContent = () => {
    return `<h6>How your income is covered</h6>
<p>Income Protection can replace your monthly income by up to 75% of your earnings after an accident or illness.</p>
<p><br></p>
<h6>Covered by your plan</h6>
<p>This is the amount that is covered by your income protection plan.</p>
<p><br></p>
<h6>Not covered</h6>
<p>This is the amount that is not covered as you can only cover up to 75% of your annual earnings.</p>
<p><br></p>
<h6>Covered by benefits</h6>
<p>This is the amount that may be covered by the state and other benefit plans.</p>
<p><br></p>`
}

export const moreInfoProps: MoreInfoProps = {
    modalContent: getModelContent(),
    buttonLabel: 'More information',
    img: 'https://assets-eu-01.kc-usercontent.com/ffd8d21b-ebd4-0151-55d5-297335c8f50f/f2023879-48b2-416f-8c20-e66002e8d1c3/question%20mark.svg',
    imgAltText: 'question mark icon'
}