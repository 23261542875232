import React from 'react'
import { Box, FormLabel } from '@chakra-ui/react'
import PhoneInputWithCountry from 'react-phone-number-input'
import { Controller } from 'react-hook-form'
import { getStyles } from '../../styles/getStyles'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'
import { isPhoneValid } from './utils'
export const PhoneNumberInput: React.FC<CalculatorInputType> = ({
    placeholder,
    fieldName,
    control,
    label,
    errorMessage,
    setIsTouched,
    isInvalid,
}) => {
    return (
        <>
            {/* @ts-ignore */}
            <Controller
                control={control}
                name={fieldName ?? ''}
                defaultValue={''}
                rules={{
                    required: errorMessage,
                    minLength: {
                        value: 10,
                        message: errorMessage ?? '',
                    },
                    validate: {
                        isPhoneValid,
                    },
                }}
                render={({ onChange, onBlur, value }) => (
                    <Box id='phone' sx={getStyles({ isInvalid })}>
                        <FormLabel>
                            <Text
                                variant={'body-md'}
                                color={'monochrome.800'}
                                fontWeight={'semibold'}
                            >
                                {label}
                            </Text>
                        </FormLabel>
                        <PhoneInputWithCountry
                            id={fieldName}
                            name={fieldName ?? ''}
                            initialValueFormat='national'
                            defaultCountry='IE'
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}
                            onBlur={() => setIsTouched && setIsTouched(true)}
                        />
                    </Box>
                )}
            />
        </>
    )
}

