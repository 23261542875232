export const quoteCardData = {
    assumptions: {
        id: 1,
        props: {
            label: `Assumptions`,
            data: [
                `Your retirement age is 55 and this is the age the cover will cease.`,
                `The deferred period is 26 weeks.`,
                `You have no children.`,
                `You pay income tax at a rate of 40%`,
                `You want the cover to increase with inflation.`,
                `You have no existing income protection cover`,
                `You are entitled to any state disability benefit`
            ],
        },
    },
}
