import { Flex, Box, Divider } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import Charts from './components/charts'
import { AdjustmentControls } from '../../../PensionCalculatorQuote/components/PensionCalculatorOutput/components/AdjustmentControls'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { useIncomeProtectionCalculatorCalculator, getStarted } from './utils/useIncomeProtectionCalculator'
import { getNextContribution } from '../../../PensionCalculatorQuote/components/PensionCalculatorOutput/utils/getNextContribution'
import { AssumptionsCard } from './components/AssumptionsCard'
import { YourDetailsCard } from './components/YourDetailsCard'
import { RichText } from 'common/components/molecules/RichText'
import { Loading } from 'common/components/organisms/Calculator/components/quoteComponents/Loading'
import { GetStarted } from '../../../common/GetStarted'
import { MoreInfo } from '../../../common/MoreInfo'
import { moreInfoProps } from './utils/useIncomeProtectionCalculator'

export interface IncomeProtectionCalculatorOutputProps { }

export const IncomeProtectionCalculatorOutput: React.FC<IncomeProtectionCalculatorOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        const isRefetching = state.matches('Refetching Quote Details')
        const {
            state: {
                income,
                amountToProtect,
                premium,
                coveredIncome,
                stateCover,
            },
            actions: { setYearlyIncome, setAmountToProtect }
        } = useIncomeProtectionCalculatorCalculator()
        return (
            <>
                <Card>
                    <Text variant={'title-sm'} mb={'12px'}>
                        {' '}
                        Your quote{' '}
                    </Text>
                    {isRefetching ? (
                        <Loading value={'premium'} />
                    ) : (
                        <>
                            <Flex mb={'28px'}>
                                <Text
                                    variant={'title-xl'}
                                    color={'var(--Brand-800, #1D2F7C)'}
                                >
                                    {`€ ${premium.toLocaleString()}`}{' '}
                                </Text>
                                <Text
                                    pt={'14px'}
                                    pl={'6px'}
                                    variant={'body-md'}
                                >
                                    /per month
                                </Text>
                            </Flex>
                            <Text variant={'title-xs'}>How your income is covered:</Text>
                            <MoreInfo {...moreInfoProps} />
                            <Charts />
                        </>
                    )}

                    <Box mt={'28px'}>
                        <Text variant={'title-xs'} mb={'16px'}>Change amounts</Text>
                        <AdjustmentControls
                            isDisabled={isRefetching}
                            label='Adjust your yearly income'
                            value={income}
                            min={1}
                            onDecrease={() =>
                                setYearlyIncome(
                                    getNextContribution(income, false)
                                )
                            }
                            onIncrease={() =>
                                setYearlyIncome(
                                    getNextContribution(income, true)
                                )
                            }
                        />
                        <AdjustmentControls
                            isDisabled={isRefetching}
                            label='Amount of income to protect'
                            max={75}
                            min={5}
                            value={amountToProtect}
                            onDecrease={() =>
                                setAmountToProtect(amountToProtect - 1)
                            }
                            onIncrease={() =>
                                setAmountToProtect(amountToProtect + 1)
                            }
                        />
                        <RichText
                            label={`${amountToProtect}% = ${coveredIncome + stateCover
                                }`}
                        ></RichText>
                    </Box>
                    <Divider
                        my={'28px'}
                        orientation='horizontal'
                        borderColor={'monochrome.100'}
                        borderWidth='1px'
                    />
                    <Box>
                        <Text variant={'title-xs'}>Your annual benefit</Text>
                        <Text variant={'body-md'} mt={'28px'}>
                            Your annual income protection benefit is{' '}
                            <b>{`€${coveredIncome.toLocaleString()}`}</b>
                        </Text>
                        <Text my={'12px'} variant={'caption-lg'}>
                            What this means:
                        </Text>
                        <Text variant={'body-md'} mb={'32px'}>
                            {` To protect ${amountToProtect.toLocaleString()}% (€${(
                                coveredIncome + stateCover
                            ).toLocaleString()}) of your total income of
                            €${income.toLocaleString()} you need to insure €${coveredIncome.toLocaleString()}. The remaining
                            €${stateCover.toLocaleString()} is covered by the state.`}
                        </Text>
                    </Box>
                </Card>
                <Card px={'36px'} mt={'16px'}>
                    <GetStarted {...getStarted} />
                </Card>
                <AssumptionsCard />
                <YourDetailsCard
                    income={income}
                    amountToProtect={amountToProtect}
                />
            </>
        )
    }
