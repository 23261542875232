import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent'
import { stepperSteps } from '../utils/progressIndicatorSteps'
import { ProgressBarStepperStepsType } from '../utils/interfaces'

export const onInteracted = (
    name: string,
    event: string,
    isInteracted: string
) => {
    fireAnalyticsEvent({
        name: name,
        properties: {
            step: event,
            event: name,
            interacted: isInteracted,
        },
    })
}

export const onButtonClicked = (
    name: string,
    step: number,
    event: string,
    context: any
) => {
    let calc_type: keyof ProgressBarStepperStepsType = context.calc_type
    if (calc_type === 'OnePlan' && context.transformedQuestions.length === 4) {
        calc_type = 'OnePlanWithPartner'
    }
    const stepName = stepperSteps[calc_type][step].title
    fireAnalyticsEvent({
        name: name,
        properties: {
            step: stepName.toString() + '_details_' + event,
            event: name,
        },
    })
}

export const onCompleted = (name: string, event: string) => {
    fireAnalyticsEvent({
        name: name,
        properties: {
            event: name,
            step: event,
        },
    })
}

export const handleOtherAnalytics = (
    analyticsName: string,
    formValues: any,
    screenNo: number
) => {
    const calculatorType = analyticsName.split('_')[0]
    if (calculatorType === 'Pensions' && screenNo === 0) {
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                step: 'employment status',
                value: formValues.employmentStatus,
            },
        })
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                step: 'current pension',
                value: formValues.currentPension ?? 'N',
            },
        })
    }
}

export const quoteScreenAnalytics = (
    analyticsName: string,
    callToAction: {
        text: string
        url: string,
        analyticsId: string
    },
    isPhoneNumber = false
) => {
    if (isPhoneNumber) {
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                clickToCall: callToAction.text,
                clickURL: callToAction.url || '',
                clickID: callToAction.analyticsId || ''
            }
        })
    } else {
        fireAnalyticsEvent({
            name: analyticsName,
            properties: {
                event: analyticsName,
                clickText: callToAction.text,
                clickURL: callToAction.url || '',
                clickID: callToAction.analyticsId || ''
            }
        })
    }
}

export const analyticsService = {
    onInteracted: onInteracted,
    onCompleted: onCompleted,
    onButtonClicked: onButtonClicked,
    handleOtherAnalytics: handleOtherAnalytics,
}
