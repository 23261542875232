import { isValidPhoneNumber } from 'react-phone-number-input/max'

export const isValid = (value: string, fieldName?: string, calcType?: string, formValues?: any) => {
    if (calcType === 'IncomeProtection' && fieldName === 'dateOfBirth') {
        console.log('value in isValid', value)
        return (+value <= 54)
    }
    if (calcType === 'IncomeProtection' && fieldName === 'amountToProtect') {
        const amountPercentageToProtect =
            (+formValues?.income * +formValues?.amountToProtect) / 100
        return (amountPercentageToProtect <= 250000 && amountPercentageToProtect >= 750)
    }
    return true
}
export const isValidEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )
}
export const isPhoneValid = (phoneNumber: string) => {
    return isValidPhoneNumber(phoneNumber)
        ? undefined
        : 'Please enter a valid mobile number. We will only contact you about your calculation.'
}