import React from 'react'
import { Box, FormLabel } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { getStyles } from '../../styles/getStyles'
import NumberFormat from 'react-number-format'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { CalculatorInputType } from 'common/components/organisms/Calculator/utils/interfaces'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { isValid } from './utils'
export const CurrencyInput: React.FC<CalculatorInputType> = ({
    label,
    fieldName,
    control,
    errorMessage,
    setIsTouched,
    isInvalid,
    defaultValue = '',
}) => {
     const { state } = useCalculatorMachine()
    const calcType = state.context.calc_type
    return (
        <>
            <Controller
                control={control}
                name={fieldName ?? ''}
                defaultValue=''
                rules={{
                    required: errorMessage,
                    validate: (value) =>
                        isValid(value, fieldName, calcType) ? undefined : errorMessage,
                }}
                render={({ onChange, onBlur, value }) => (
                    <Box sx={getStyles({ isInvalid })}>
                        <FormLabel>
                            <Text
                                variant={'body-md'}
                                color={'monochrome.800'}
                                fontWeight={'semibold'}
                            >
                                {label}
                            </Text>
                        </FormLabel>
                        <Box pos={'relative'}>
                            <span
                                style={{
                                    position: 'absolute',
                                    left: '5px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }}
                            >
                                €
                            </span>
                            <NumberFormat
                                id='currency'
                                thousandSeparator={true}
                                defaultValue={defaultValue}
                                onValueChange={({ value }) => onChange(value)}
                                name={fieldName}
                                onBlur={() =>
                                    setIsTouched && setIsTouched(true)
                                }
                            />
                        </Box>
                    </Box>
                )}
            />
        </>
    )
}
