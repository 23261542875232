const formatIconPosition = (iconPosition?: string) => {
    if(iconPosition === 'start') return 'leftIcon';
    if(iconPosition === 'end') return 'rightIcon';
    return ''
}

export const getCallToActionData = (data: any) => {
    let iconPosition = formatIconPosition(data?.call_to_action__iconlocation);
    
    return {
        analyticsId: data?.call_to_action__analytics_id,
        label: data?.call_to_action__label,
        jumbotronLabel: data?.call_to_action__jumbotron_label,
        labelMobile: data?.call_to_action__label_mobile,
        [iconPosition]:
            data?.call_to_action__icon,
        text: data?.call_to_action__text,
        title: data?.call_to_action__title,
        titleMobile: data?.call_to_action__title_mobile,
        url: data?.call_to_action__url,
        jumbotronUrl: data?.call_to_action__jumbotron_url,
        variant: data?.call_to_action__variant,
    }
}

export const getSecondaryCallToActionData = (data: any) => {
    let iconPosition = formatIconPosition(data?.call_to_action2__icon_location);
    return {
        analyticsId: data?.call_to_action2__analytics_id,
        label: data?.call_to_action2__label,
        [iconPosition]:
            data?.call_to_action2__icon,
        url: data?.call_to_action2__url,
        variant: data?.call_to_action2__variant,
    }
}