import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { PhoneIcon } from '@irishlife/ilgroupdesignsystem.icons'
export interface HaveAQuestionCardProps {
    phoneNumber?: string
}

export const HaveAQuestionCard: React.FC<HaveAQuestionCardProps> = ({phoneNumber= '(01)7041979'}) => {
    const phone = `tel: +353${phoneNumber}`
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Card px={'36px'}>
                <Text variant={'title-sm'} color={'var(--Brand-800, #1D2F7C)'}>
                    Have a question about your quote?
                </Text>
                <Box mt={'20px'}>
                    <Text mb={'16px'} variant={'body-md'}>
                        Call us if you have any questions about your quote.
                    </Text>
                    <a href={phone}>
                        <Button
                            leftIcon={
                                <PhoneIcon fontSize={'28px'} color={'#FFFFF'} />
                            }
                            variant='primary'
                            size='md'
                        >
                        {`Call us ${phoneNumber}`}
                        </Button>
                    </a>
                </Box>
            </Card>
        </Box>
    )
}
