import { Box, Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { MinusIcon, PlusIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React, {useEffect} from 'react'
import AnimatedNumber from 'react-animated-number'
import { Controller } from 'react-hook-form'
import { formatValue } from './utils/stepsUtils'
import { HelperText } from './HelperText'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { isValid } from './../inputs/utils'
export interface CalculatorStepAdjustmentControls {
    isDisabled?: boolean
    label: string
    value?: number
    onDecrease: () => void
    onIncrease: () => void
    onReset?: () => void
    min?: number
    max?: number
    control?: any
    fieldName?: string
    errorMessage?: string
    defaultValue?: number
    stepSize: number
    inputtype: string
    helperText?: string
    formValues?: any
    errors?:any
    trigger?: any
}

export const CalculatorStepAdjustmentControls: React.FC<CalculatorStepAdjustmentControls> =
    ({
        control,
        isDisabled,
        fieldName,
        errorMessage,
        label,
        value,
        onDecrease,
        onIncrease,
        onReset,
        min,
        max,
        errors,
        trigger,
        stepSize,
        defaultValue,
        inputtype,
        helperText,
        formValues,
    }) => {
          const { state } = useCalculatorMachine()
            const calcType = state.context.calc_type
            useEffect(() => {
                trigger(fieldName);
            }, [trigger,  formValues, value, fieldName]);
        return (
            <>
                <Controller
                    control={control}
                    name={fieldName ?? ''}
                    defaultValue={defaultValue}
                    rules={{
                        required: errorMessage,
                        validate: (value) =>
                            isValid(value, fieldName, calcType, formValues) ? undefined : errorMessage,
                    }}
                    render={({ onChange, onBlur, value }) => (
                        <Box py={'12px'}>
                            <Text variant={'body-md'}>{label}</Text>
                            <ButtonGroup
                                pointerEvents={isDisabled ? 'none' : 'auto'}
                                pt={'6px'}
                                size='md'
                                isAttached
                                width={'164px'}
                                h={'48px'}
                                borderColor='#F1F3FD'
                                variant='outline'
                            >
                                <IconButton
                                    backgroundColor='#F1F3FD'
                                    color={
                                        isDisabled
                                            ? 'monochrome.700'
                                            : 'vibrant.700'
                                    }
                                    borderColor='#FFFFFF'
                                    disabled={min ? value <= min : false}
                                    aria-label='Decrease'
                                    icon={<MinusIcon />}
                                    onClick={() => {
                                        onChange(value - stepSize)
                                    }}
                                >
                                    -
                                </IconButton>
                                <Button
                                    color={
                                        isDisabled
                                            ? 'monochrome.700'
                                            : 'vibrant.700'
                                    }
                                    minW='76px'
                                    pointerEvents='none'
                                    backgroundColor='#F7F8FB'
                                    borderColor='#FFFFFF'
                                    onClick={() => onReset?.()}
                                >
                                    <AnimatedNumber
                                        duration={200}
                                        formatValue={(value: number) =>
                                            formatValue(value, inputtype)
                                        }
                                        value={value}
                                    />
                                </Button>
                                <IconButton
                                    color={
                                        isDisabled
                                            ? 'monochrome.700'
                                            : 'vibrant.700'
                                    }
                                    backgroundColor='#F1F3FD'
                                    borderColor='#FFFFFF'
                                    disabled={max ? value >= max : false}
                                    aria-label='Increase'
                                    icon={<PlusIcon />}
                                    onClick={() => {
                                        onChange(value + stepSize)
                                    }}
                                >
                                    +
                                </IconButton>
                            </ButtonGroup>
                        </Box>
                    )}
                />
                <HelperText
                    errors={errors}
                    fieldName={fieldName}
                    helperText={helperText}
                    formValues={formValues}
                />
            </>
        )
    }
