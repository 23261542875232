import { getOccupationClass } from "../config/incomeProtection"
import { IncomeProtectionFormType } from "./interfaces"

export const checkIfOccupationRestriction = (state: any) => {
    if (state.context.calc_type === 'IncomeProtection') {
        return checkOccupationClass((state.context.formValues as IncomeProtectionFormType).occupation)
    }
}

const checkOccupationClass = (occupation: string) => {
    const occupationClass = getOccupationClass(occupation)
    return occupationClass && occupationClass.BillPayOccupationClass === 'D'
}